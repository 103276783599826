.App {
  text-align: center;
  display:flex;
  background-color: #ededed21;
  color: brown;
  position: absolute;
  top:0; bottom:0; left:0; right: 0;
  background-color: gray;
}

.sidemenu {
  width: 140px;
  height:400px;
  border:1px solid blue;
  padding:10px;
}

.side-menu-button {
  padding:15px;
  border:1px solid black;
  border-radius:5px;
  text-align:left;
  transition: ease 0.25s all;
}
.side-menu-button:hover {
  background-color: rgba(255,255,255,0.1);
}

.chat-input-textarea {
  color:white;

  background-color: #40414f;
  width:100%;
  border-radius: 5px;
  border:none;
  marginZZZ:12px;
  outline: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5)

}

.chatbox{
  flex:1;
  border:1px solid red;

}

.myForm{
  width:100%;
  border:1px solid blue;
}



/* ----------------------------------------- */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cnvAppContainer { color: rgb(9, 17, 14);background-color: #c0cbe0;}


.conversationId {
  font-weight: 300; font-size: 20px;
}


/*  */

.cnvToggle {
  overflow: scroll;
  height: 100%;
  border: 1px solid green;
}

.cnvTapeQuestionRow {
  background-color: #dbdbdb !important;
}

.cnvTapeAnswerRow {
  background-color: #ededed !important;
}

.cnvDebugClass{
  height: 200px;
  background-color: lightblue !important;
  overflow-y: scroll;
}

.cnvQuestion {
  border: 1px solid black;
  border-radius: 5px;
  margin: 2px 2px 2px 2px;
}
.cnvAnswer {
  border: 1px solid black;
}

.cnvScroll{
  width:100%;
  height: 400px;
  background-color: lightyellow;
  padding-left: 10px; padding-right: 10px;
  overflow-y: scroll;
  text-align: left;
}
/* */
.AiPage_ModalParams_Outer{
  position:fixed; left:0; right:0; top:0; bottom:0;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items:center;
  justify-content:center;

}
.AiPage_ModalParams_Inner{
  width:90%;
  height:90%;
  padding-top:100px; 
  
  border-color: 2px solid blueviolet;

  background-color: white;
  

}
/*------------------------------------------------ */

/* body{
  background: #0a0a23;
  min-height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
}
 */
.a_dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
}

.a_dropdown-button, .cnvSelect{
  border:none;
  border-radius:5px;
  padding:15px 30px;
  /* margin:10px; */
  font-size:18px;
  cursor:pointer;
}

.a_dropdown-button:hover{
  background-color:#ddd;
  /* background-color:red; */
}

.a_dropdown-options {
  display: none;
  position: absolute;
  overflow: auto;
  background-color:#fff;
  border-radius:5px;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
}

.a_dropdown:hover .a_dropdown-options {
  display: block;
}

.a_dropdown-options a {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
  padding:20px 40px;
}

.a_dropdown-options a:hover {
  color: #0a0a23;
  background-color: #ddd;
  border-radius:5px;
}
/*------------------------------------------------------*/
.pnifHide {
  opacity: 25%;
}
/*------------------------------------------------------*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* //////////// styles for new ui////////////// */


/* ----------------------- */
.cnvIdSelected {
  background-color: gray !important
}


.cnvId_list {
  opacity:1.0;
  border: 3px solid green;
}
/* ------------------- */
.cnvFunc_list {
  background-color: #b7b4c5 !important; /* Lighter shade of gray */
  padding: 0px; /* if > 0 displays ugly small rect */
  margin: 10px;
  z-index: 1000; /* Add this line */
}

.cnvFunc_list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cnvFunc_list li {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  background-color: #7a6f6f !important;
}

.cnvFunc_list li:hover {
  background-color: #7a6f6f !important;
}

/*------------------------- */


#cnvLeftPane {
  background-color: black;
  border: 3px solid red;
}


#cnvLeftPane *,
#cnvLeftPane ul,
#cnvLeftPane li {
  color: white;
  background-color: black;
}
/*------------------------*/



.cnvLeftPane_header {
  display: flex;
  /* align-items: center; */
  flex-wrap: nowrap;
}

.cnvLeftPane_header > div {
  margin-left: 8px;
}

.cnvLeftPane_header button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------ */
