.feedback-dialog {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 400px; /* Adjust as needed */
}

.feedback-dialog form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  resize: vertical;
}

.form-group input {
  width: 100%;
  padding: 10px;
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
}

.dialog-actions button {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color:#007bff; 
  /* background-color:rgb(35, 35, 126); */
  color: white;
  border: none;
  border-radius: 5px;
}

.dialog-actions button:hover {
  opacity: 0.8;
}

.dialog-actions button[type="button"] {
  background-color: #6c757d;
}
