@const Angry_Color : #5F1202 

/* Box-sizing: To avoid any surprises due to padding and borders, set box-sizing to border-box for all elements. This is usually done globally in many projects to ensure consistent layout calculations: */
* {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* .hide-scroll-bar {
  -ms-overflow-style: none;  / * IE and Edge * /
  scrollbar-width: none;  / * Firefox * /
} */

.beta-at-header {
  font-style: italic;
  font-size: 1.2em;          /* Reduce font size */
  vertical-align:bottom;   /* Raise the position */
  color: blue;              /* Make it noticeable, choose any color you like */
  font-weight: 400;       /* Make it bold */
  margin-left: 5px;        /* Add some spacing between the title and the beta tag */
}

.send-feedback {
  margin-left: 4px; /* or however much space you want */
  margin-right: 4px;
  text-decoration: none;
  /*color: #000;  This should be whatever color your links are */
}

.send-feedback:hover {
  text-decoration: underline;
}


.fullSiteMode::-webkit-scrollbar {
  width: 5px;  /* Adjust width for vertical scrollbars */
  height: 5px;  /* Adjust height for horizontal scrollbars */
}

.fullSiteMode::-webkit-scrollbar-thumb {
  background-color: #888;  /* Color of the scroll handle */
  border-radius: 2px;  /* Rounded corners */
}

/* Optional: Style for hover effect */
.fullSiteMode::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* ////////////////////////////////////////////// */

.angry-title {
  font-weight: bolder;
  font-size: 14px
}

.angry-quote {
  font-weight: bolder;
  font-size: 14px
}
/* //////////////////////////////////// */
.buttonActiveStyle  {
  font-size: inherit; 
  opacity: 1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8) 
}

.buttonInactiveStyle  {
  font-size: inherit;
  opacity: 0.4;
  box-shadow: none
}

/* .buttonSmallStyle {
  opacity: 0.8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8) 

  , border-radius: 0
} */

/* ////////////////////////////////////////////// */
/* a:hover span { */
.linkLikeBehaviour:hover {
  color: blue; /* Change color to blue on hover */
  text-decoration: underline; /* Add underline on hover */
  /* Add other hover styles as needed */
}
/* //////////////// animating progress /////////////////// */
/* https://www.w3schools.com/css/css3_animations.asp */
@keyframes blinkBorder {
  from {border-bottom-color: original;}
  to {border-bottom-color: rgb(145, 3, 3); 
    /* border-bottom:4px */
  }
}
.animated {
  /* border-bottom: 1px solid original !important;  */
  animation-name: blinkBorder;
  animation-duration: 1.5s;
  animation-delay: 0.5s; /*give app a chance to come back sooner*/
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
/* ///////////////////////////////////// */

    .advisoryMessage {
      display: inline-block 

      /* ;border: 1px solid brown
      ;background-color: lightyellow; */

      ;margin:0px 60px 0px 0px
      ;font-weight:600 
      ;color:brown 

    }
  /* /////////////////////////////////////////// */
  /* hourglass.css */
/* @keyframes hourglass {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hourglass {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #333;
  border-radius: 50%;
  animation: hourglass 2s linear infinite;
} */
/* /////////////////////////////////////////// */